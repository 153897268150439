/*----------------------------------------------
	ハンバーガーメニュークリックで、背面固定
---------------------------------------------*/

let navElem = document.getElementById('hamburger-icon');
let htmlElem = document.querySelector('HTML');
let bodyElem = document.querySelector('body');

navElem.addEventListener ('click', () => {
	htmlElem.classList.toggle('u_untouch');
	bodyElem.classList.toggle('u_untouch');
}, false);


/*----------------------------------------------
	ページの一番上に戻るボタンの表示/非表示
---------------------------------------------*/

let pageTop = () => {
	//スクロール量を取得する関数
	function getScrolled() {
		return ( window.pageYOffset !== undefined ) ? window.pageYOffset: document.documentElement.scrollTop;
	}

	//トップに戻るボタンの要素を取得
	let topButton = document.getElementById( 'js_page-top' );

	//ボタンの表示・非表示
	window.addEventListener ('scroll', () => {
		( getScrolled() > 500 ) ? topButton.classList.add( 'is_fade-in' ): topButton.classList.remove( 'is_fade-in' );
	});
};

pageTop();


/*----------------------------------------------
	スクロールしたときにヘッダーを小さくする
---------------------------------------------*/

let changeHeaderSize = () => {
	//スクロール量を取得する関数
	function getScrolled() {
		return ( window.pageYOffset !== undefined ) ? window.pageYOffset: document.documentElement.scrollTop;
	}

	//トップに戻るボタンの要素を取得
	let header = document.getElementById( 'js_header' );

	//ボタンの表示・非表示
	window.addEventListener ('scroll', () => {
		( getScrolled() > 500 ) ? header.classList.add( 'is_size-changed' ): header.classList.remove( 'is_size-changed' );
	});
};

changeHeaderSize();
